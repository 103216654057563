import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import NAV_DATA, {
  InitNavDataEventPayload,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { SecondaryType } from 'components/common/header/UnifiedNav/UnifiedNav';
import { isPostAuthNav3MobileOrDesktop } from 'util/isPostAuthNav3Active';

const {
  EVENTS: { INIT_NAV_DATA },
} = NAV_DATA;

export const emitUpdateNavDataEvent = ({
  userContext,
  secondaryData,
  activePrimaryLink,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse,
  disableBannerCollapse,
  emitDirectionEvents,
  tertiaryData,
}: Partial<InitNavDataEventPayload>): void => {
  window.dispatchEvent(
    new CustomEvent<Partial<InitNavDataEventPayload>>(INIT_NAV_DATA, {
      detail: {
        userContext,
        secondaryData,
        activePrimaryLink,
        activeSecondaryLink,
        activeTertiaryLink,
        disablePrimaryNavCollapse,
        disableBannerCollapse,
        emitDirectionEvents,
        tertiaryData,
      },
    })
  );
};

export const shouldShowWebsiteSecondaryNav = (userContext: UserContext) => {
  if (isPostAuthNav3MobileOrDesktop(userContext)) {
    return userContext.has_website;
  }

  return false;
};

export const getWebsiteSecondaryNavType = (userContext: UserContext) => {
  return shouldShowWebsiteSecondaryNav(userContext) ? SecondaryType.SECONDARY_NAV_WEBSITE : '';
};

export const getDesktopNavHeight = (disableSecondary: boolean, isGuest: boolean): number => {
  // Pre-auth nav is larger
  const heightWithSecondary = isGuest ? 189 : 140;
  const heightWithoutSecondary = isGuest ? 153 : 104;

  return disableSecondary ? heightWithoutSecondary : heightWithSecondary;
};

export const getNavHeight = (disableSecondary: boolean, isGuest: boolean): number => {
  if (isDesktop()) {
    return getDesktopNavHeight(disableSecondary, isGuest);
  }
  return 60;
};

export default {
  emitUpdateNavDataEvent,
  getNavHeight,
};
