import ZuiApiService from '@zola-helpers/client/dist/es/http/api';
import featureFlags from '../util/featureFlags';
import ApiService from '../util/api';

export function update() {}

export function getNav() {
  if (featureFlags.get('navSsr')) {
    return () => ZuiApiService.get('/website-nav/web-api/legacyNav');
  }
  return () => ApiService.get('/web-api/v1/nav/get', {}, {}, 'text').then(text => text);
}
